import * as Auth from 'aws-amplify/auth';
import {generatePassword} from '../../utils';

export const requestAuthChallenge = (email: string) =>
  Auth.signIn({
    username: email,
    options: {authFlowType: 'CUSTOM_WITHOUT_SRP'},
  });
export const loginWithGoogle = () => Auth.signInWithRedirect({provider: 'Google'});
export const logout = async () => {
  await Auth.signOut();
  location.reload();
};
export const signUp = (email: string) =>
  Auth.signUp({
    username: email,
    password: generatePassword(30),
    options: {
      // autoSignIn: {
      // authFlowType: 'CUSTOM_WITHOUT_SRP', // not working, with this option enabled we could stop sending password
      // },
      userAttributes: {
        // legacy needs this, later in onboarding is filled
        given_name: '',
        family_name: '',
      },
      clientMetadata: {
        origin: 'WebApp',
      },
    },
  });

export const login = ({code}: {code: string}) =>
  Auth.confirmSignIn({
    challengeResponse: code,
    options: {authFlowType: 'CUSTOM_CHALLENGE'},
  });

export const checkAuth = () => Auth.getCurrentUser().then(user => Boolean(user));

export const getJWT = () => Auth.fetchAuthSession().then(session => session.tokens?.idToken?.toString());

const SESSION_KEY_PREFIX = 'CognitoIdentityServiceProvider.';
export const dumpSessionToObject = () => {
  const entries = Object.entries(localStorage).filter(([key]) => key.startsWith(SESSION_KEY_PREFIX));
  return Object.fromEntries(entries);
};
