import {useEffect, useState} from 'react';
import {dumpSessionToObject} from '../providers';
import {sendDataToExtension} from '../utils';
import {getExtensionVersion, getIsExtensionAuthenticated} from '../utils/extensionCommunication';

export const useBrowserExtension = () => {
  const [isReady, setIsReady] = useState(false);
  const [extensionVersion, setExtensionVersion] = useState<string | null>(null);
  const [isExtensionAuthenticated, setIsExtensionAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    if (extensionVersion !== null && isExtensionAuthenticated !== null) {
      setIsReady(true);
    }
  }, [extensionVersion, isExtensionAuthenticated]);

  getExtensionVersion().then(version => {
    setExtensionVersion(version);
  });

  const hasExtension = !!extensionVersion;

  const passSessionDump = async () => {
    const sessionDump = JSON.stringify(dumpSessionToObject());
    sendDataToExtension('passSessionDump', sessionDump);
  };

  getIsExtensionAuthenticated().then(isAuth => {
    setIsExtensionAuthenticated(isAuth);
  });

  return {
    isReady,
    hasExtension,
    extensionVersion,
    isExtensionAuthenticated,
    passSessionDump,
  };
};
