import {differenceInDays} from 'date-fns';
import {useCallback, useMemo, useState} from 'react';
import {FiRefreshCcw as RefreshIcon} from 'react-icons/fi';
import {Button, ButtonProps} from '../../components/button/Button';
import {Tooltip} from '../../components/tooltip';
import {useBrowserExtension, useDebounce} from '../../hooks';
import {addHttpsToUrl, sendDataToExtension} from '../../utils';

const persistClicked = (profileAddress: string) => {
  const key = `refreshClicked_${profileAddress}`;
  const date = new Date();
  localStorage.setItem(key, date.toISOString());
};
const isClickedWhilstLastDay = (profileAddress?: string) => {
  if (!profileAddress) {
    return false;
  }

  const key = `refreshClicked_${profileAddress}`;
  const clicked = localStorage.getItem(key);

  if (!clicked) {
    return false;
  }

  return differenceInDays(new Date(), new Date(clicked)) < 1;
};

const VERIFICATION_DELAY = 3000;
const DISABLE_DELAY = 1000 * 60 * 5; // 5 minutes

const initialLabel = 'Refresh data';
const pendingLabel = 'Refreshing... Please wait...';

type Props = {
  profileAddress?: string;
};
export const RefreshProfileButton = ({profileAddress}: Props) => {
  const [initiallyDisabled] = useState(() => isClickedWhilstLastDay(profileAddress)); // as useState, because it's a one-time check
  const {hasExtension} = useBrowserExtension();
  const [isClicked, setIsClicked] = useState(false);
  const verifiedAfterClick = useDebounce(isClicked, VERIFICATION_DELAY);
  const disabledAfterClick = useDebounce(isClicked, DISABLE_DELAY);

  const onClick = useCallback(() => {
    setIsClicked(true);
    if (profileAddress) {
      persistClicked(profileAddress);
    }
    if (hasExtension) {
      sendDataToExtension('markProfileForActiveScrape', profileAddress);
    }
  }, [hasExtension, profileAddress]);

  const clickedButtonProps = useMemo(() => {
    return {
      variant: isClicked ? 'tertiary' : 'primary',
      onClick,
      className: isClicked ? 'animate-pulse' : '',
      disabled: isClicked,
      icon: <RefreshIcon />,
      tracking: {label: 'refresh data', customProperties: {has_extension: hasExtension}},
    } satisfies ButtonProps;
  }, [isClicked, hasExtension, onClick]);

  if (!profileAddress) {
    return null;
  }

  if (initiallyDisabled || disabledAfterClick) {
    return (
      <Button variant="primary" disabled icon={<RefreshIcon />}>
        {initialLabel}
      </Button>
    );
  }

  if (!hasExtension) {
    return (
      <Button data-intercom-target="Refresh data" {...clickedButtonProps}>
        {isClicked ? pendingLabel : initialLabel}
      </Button>
    );
  }

  const refreshBtn = (
    <Button
      {...clickedButtonProps}
      href={addHttpsToUrl(profileAddress)}
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      {isClicked ? (!verifiedAfterClick ? 'Verification in progress...' : pendingLabel) : initialLabel}
    </Button>
  );

  return isClicked ? (
    refreshBtn
  ) : (
    <Tooltip content="We'll promptly open the LinkedIn page of this profile to confirm the latest updates for you.">
      {refreshBtn}
    </Tooltip>
  );
};
