import {AxiosResponse} from 'axios';
import {AxiosError} from 'axios/index';
import {useMutation, useQuery, UseQueryOptions} from 'react-query';
import {UseMutationOptions} from 'react-query/types/react/types';
import {ErrorResponse, UserSessionData} from '../types';
import {apiClient} from './apiClient';

type UserSessionResponse = UserSessionData;
const getUserMe = (): Promise<UserSessionData> =>
  apiClient.get<UserSessionResponse>('/users/me').then(res => res.data);

export const useQueryUserSession = (options?: UseQueryOptions<UserSessionData>) =>
  useQuery<UserSessionData>('user-session', getUserMe, options);

// UPDATE USER LINKEDIN
type UpdateUserLinkedinURLInput = {
  linkedinUrl: string;
};
export const updateUserLinkedinURL = (userId: string, linkedinUrl: string): Promise<void> =>
  apiClient
    .post<void, AxiosResponse<void>, UpdateUserLinkedinURLInput>(`/users/${userId}/linkedin`, {
      linkedinUrl,
    })
    .then(res => res.data);

export const useMutationUpdateUserLinkedinURL = (
  userId: string,
  options?: UseMutationOptions<unknown, AxiosError<ErrorResponse>, UpdateUserLinkedinURLInput>
) =>
  useMutation<unknown, AxiosError<ErrorResponse>, UpdateUserLinkedinURLInput>(
    ({linkedinUrl}) => updateUserLinkedinURL(userId, linkedinUrl),
    options
  );

// UPDATE USER FIRST AND LAST NAME
type UpdateUserInput = {
  firstName: string;
  lastName: string;
};
export const updateUser = (userId: string, userData: UpdateUserInput): Promise<void> =>
  apiClient
    .patch<void, AxiosResponse<void>, UpdateUserInput>(`/users/${userId}`, userData)
    .then(res => res.data);

export const useMutationUpdateFirstLastName = (
  userId: string,
  options?: UseMutationOptions<void, AxiosError<ErrorResponse>, UpdateUserInput>
) =>
  useMutation<void, AxiosError<ErrorResponse>, UpdateUserInput>(
    ({firstName, lastName}) => updateUser(userId, {firstName, lastName}),
    options
  );

export const setOrigin = () =>
  apiClient.post<void, AxiosResponse<void>>('/users/me/origin', {origin: 'WebApp'}).then(res => res.data);
