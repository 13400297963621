import {trackError} from './3rdParty';
import {customEventListener, customEventSender} from './customEventHandler';

// sender
export const toEventName = 'swarmToExtension';
export type ToEventsMap = {
  getExtensionVersion: void;
  getUserBlueUrl: void;
  isBlueAuthenticated: void;
  isExtensionAuthenticated: void;
  passSessionDump: void;
  markProfileForActiveScrape: string;
};
export const sendDataToExtension = customEventSender<ToEventsMap>(toEventName);

// receiver
export const fromEventName = 'swarmFromExtension';
export type FromEventsMap = {
  blueAuthenticated: boolean;
  extensionAuthenticated: boolean;
  userBlueUrl: string;
  extensionVersion: string;
  test: number;
};

export const {addGlobalListener, addKeyListener, callbackKeyFilter} = customEventListener<FromEventsMap>(
  fromEventName,
  trackError
);
