import {TabPageContent} from '../../../components/tabs';

export const ApiSupport = () => (
  <TabPageContent heading="API Support">
    <p className="text-swarm-gray-700">
      Check out our docs for details on The Swarm API –{' '}
      <a
        className="text-swarm-black underline"
        href="https://docs.theswarm.com/"
        target="_blank"
        rel="noreferrer"
      >
        https://docs.theswarm.com/
      </a>
    </p>
    <p className="text-swarm-gray-700">
      For help or questions, email us anytime at{' '}
      <a className="text-swarm-black underline" href="mailto:apisupport@theswarm.com">
        apisupport@theswarm.com
      </a>
    </p>
  </TabPageContent>
);
