import {first} from 'lodash';
import {createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {teamIdSetter, useQueryUserSession} from '../api';
import {useBrowserExtension} from '../hooks';
import {usePrevious} from '../hooks/usePrevious';
import {UserSessionData} from '../types';
import {UserSessionContextValue, UsersTeam} from './UserSessionContext.types';
import {mapMembershipToUsersTeam} from './UserSessionContext.utils';

const UserSessionContext = createContext({} as UserSessionContextValue);
UserSessionContext.displayName = 'SwarmUserSessionContext';

type Props = {
  children: ReactNode;
};

const useActiveTeamId = (allTeams: UsersTeam[], userId?: string) => {
  const [internalActiveTeam, setInternalActiveTeam] = useState<string>();
  const storageKey = useMemo(() => `activeTeam-${userId}`, [userId]);

  useEffect(() => {
    const persistedActiveTeamId = localStorage.getItem(storageKey);
    if (persistedActiveTeamId) {
      setInternalActiveTeam(persistedActiveTeamId);
    }
  }, [storageKey]);

  const activeTeamId = useMemo(() => {
    if (internalActiveTeam) {
      const team = allTeams.find(team => team.id === internalActiveTeam);
      if (team) {
        return team.id;
      }
    }

    const persistedActiveTeamId = localStorage.getItem(storageKey);
    if (persistedActiveTeamId) {
      const team = allTeams.find(team => team.id === persistedActiveTeamId);
      if (team) {
        return team.id;
      }
    }

    return first(allTeams)?.id;
  }, [internalActiveTeam, allTeams, storageKey]);

  const previousTeamId = usePrevious(activeTeamId);
  useEffect(() => {
    if (previousTeamId !== activeTeamId) {
      // @ts-expect-error activeTeamId is a string | undefined but should be a string
      teamIdSetter(activeTeamId);
    }
  }, [activeTeamId, previousTeamId]);

  const persistActiveTeam = useCallback(
    (teamId: string) => {
      localStorage.setItem(storageKey, teamId);
    },
    [storageKey]
  );

  const changeActiveTeam = useCallback(
    (teamId: string) => {
      setInternalActiveTeam(teamId);
      persistActiveTeam(teamId);
    },
    [persistActiveTeam, setInternalActiveTeam]
  );

  return {
    activeTeamId,
    changeActiveTeam,
  };
};

const emptyUser: UserSessionData = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  memberships: [],
  linkedInUrl: '',
  intercomHash: '',
};

export const UserSessionContextProvider = ({children}: Props) => {
  const {
    hasExtension,
    isExtensionAuthenticated,
    isReady: isExtensionReady,
    passSessionDump,
  } = useBrowserExtension();
  const {
    data: user = emptyUser,
    isFetched,
    isRefetching,
    refetch,
  } = useQueryUserSession({
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const teams = useMemo(() => user.memberships.map(mapMembershipToUsersTeam), [user.memberships]); // TODO: remove this mapping when the API is fixed

  const {activeTeamId, changeActiveTeam} = useActiveTeamId(teams, user.id);
  const permissionsAccesses = useMemo(() => getPermissionsAccesses(user, activeTeamId), [user, activeTeamId]);
  const value: UserSessionContextValue = {
    user,
    teams,
    isFetched,
    isRefetching,
    refetch,
    activeTeamId,
    changeActiveTeam,
    permissionsAccesses,
  };

  useEffect(() => {
    if (isFetched && isExtensionReady && hasExtension && !isExtensionAuthenticated) {
      passSessionDump();
    }
  }, [hasExtension, isExtensionAuthenticated, isExtensionReady, isFetched, passSessionDump]);

  return <UserSessionContext.Provider value={value}>{children}</UserSessionContext.Provider>;
};

export const useUserSessionContext = () => {
  return useContext(UserSessionContext);
};

const getMembership = (user: UserSessionData, activeTeamId: string | undefined) => {
  return user.memberships.find(m => m.teamId === activeTeamId);
};

export const getPermissionsAccesses = (user: UserSessionData, activeTeamId: string | undefined) => {
  return getMembership(user, activeTeamId)?.permissionAccesses || [];
};
