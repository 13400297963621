import {addKeyListener, FromEventsMap, sendDataToExtension, ToEventsMap} from './extensionListener';
import {sleep} from './sleep';

const NEXT_ATTEMPT_WAIT = 1000;
const MAX_ATTEMPTS = 10;

export const createCheckFunction = <Key extends keyof FromEventsMap>(
  action: keyof ToEventsMap,
  keyFilter: Key
) => {
  let onResponse: () => void;
  const responded = new Promise<void>(resolve => {
    onResponse = resolve;
  });
  let attempts = 0;
  let done = false;
  let value: FromEventsMap[Key];
  const detachListener = addKeyListener(keyFilter, val => {
    value = val;
    done = true;
    onResponse();
  });

  sendDataToExtension(action);

  return async () => {
    while (attempts < MAX_ATTEMPTS && !done) {
      await Promise.race([responded, sleep(NEXT_ATTEMPT_WAIT)]);
      attempts++;
    }
    detachListener();
    return value;
  };
};

export const getExtensionVersion = async () => {
  const checkVersion = createCheckFunction('getExtensionVersion', 'extensionVersion');
  return checkVersion();
};

export const getIsBlueAuthenticated = async () => {
  const checkAuthentication = createCheckFunction('isBlueAuthenticated', 'blueAuthenticated');
  return checkAuthentication();
};

export const getUserBlueUrl = async () => {
  const liFeed = 'https://linkedin.com/feed/';
  const extraWindow = window.open(liFeed, '_blank');
  const checkUserBlueUrl = createCheckFunction('getUserBlueUrl', 'userBlueUrl');
  extraWindow?.close();
  return checkUserBlueUrl();
};

export const getIsExtensionAuthenticated = async () => {
  const checkAuthentication = createCheckFunction('isExtensionAuthenticated', 'extensionAuthenticated');
  return checkAuthentication();
};
